import { Order, SpannedPrice } from '@wix/ambassador-pricing-plans-v2-order/types';

export const hasOrderCoupon = (order: Order) => {
  return Boolean(order?.pricing?.prices?.[0]?.price?.coupon);
};

export const getOrderCoupon = (order: Order) => {
  return getPricesCoupon(order?.pricing?.prices);
};

export const getPricesCoupon = (prices?: SpannedPrice[]) => {
  return prices?.[0]?.price?.coupon;
};
