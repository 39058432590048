import { Order } from '@wix/ambassador-pricing-plans-v2-order/types';
import { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import {
  checkoutStage,
  couponActionAtCheckout,
  couponErrorAtCheckout,
  planPurchased,
} from '@wix/bi-logger-membership/v2';
import { PaymentCompleteResultPublic } from '@wix/cashier-payments-widget/dist/src/sdk/types/PaymentCompleteResult';
import type { HttpError } from '@wix/fe-essentials/http-client';
import { ControllerFlowAPI, ControllerParams, HttpClient, IUser } from '@wix/yoshi-flow-editor';
import { BOOKINGS_APP_DEF_ID } from '../../../constants';
import { BenefitsApi, OrdersApi, PlansApi, PremiumApi } from '../../../services';
import { Analytics } from '../../../services/analytics';
import {
  CheckoutData,
  CheckoutProps,
  CommonProps,
  IntegrationData,
  MessageCode,
  OnBeforeStartPaymentFn,
  PromisifyFn,
} from '../../../types/common';
import { PackagePickerInteractions } from '../../../types/PackagePickerFedops';
import { toBIPaymentType } from '../../../utils/bi';
import { ymdToDate } from '../../../utils/date';
import { getOrderCoupon, getPricesCoupon } from '../../../utils/domainUtils';
import { errorToMessage, getApplicationErrorCode, PurchaseLimitExceededError, toError } from '../../../utils/errors';
import { PaymentResultReader } from '../../../utils/PaymentResultReader';
import { isFree } from '../../../utils/plan';
import { getUserData } from '../../../utils/user';
import { Router } from './Router';

enum CouponAction {
  Apply = 'apply',
  Remove = 'remove',
}

export class CheckoutController {
  constructor(
    protected setProps: (props: Partial<CommonProps & CheckoutProps>) => void,
    protected wixCodeApi: ControllerParams['controllerConfig']['wixCodeApi'],
    protected router: Router,
    protected flowAPI: ControllerFlowAPI,
    protected plansApi: PlansApi,
    protected ordersApi: OrdersApi,
    protected benefitsApi: BenefitsApi,
    protected premiumApi: PremiumApi,
    protected analytics: Analytics,
  ) {}

  async initialize(checkoutData: CheckoutData) {
    this.flowAPI.fedops.interactionStarted(PackagePickerInteractions.CheckoutPageInitialized);
    const {
      currentUser: { loggedIn, id },
    } = this.wixCodeApi.user;
    const { planId, memberId, orderId, integrationData } = checkoutData;
    let plans: PublicPlan[] = [];
    try {
      plans = await this.plansApi.loadPaidPlans({ planIds: [planId] });
    } catch (e) {
      this.flowAPI.errorMonitor.captureException(toError(e));
    }
    this.flowAPI.fedops.interactionEnded(PackagePickerInteractions.CheckoutPageInitialized);

    if (plans.length < 1) {
      this.router.gotoList(integrationData, MessageCode.PLAN_NOT_FOUND);
    } else {
      const [selectedPlan] = plans;
      const isSameMember = loggedIn && memberId === id;
      try {
        await this.update(
          selectedPlan,
          integrationData,
          isSameMember && orderId ? await this.ordersApi.getOrder(orderId) : undefined,
          true,
        );
      } catch (e) {
        if (e instanceof PurchaseLimitExceededError) {
          this.setProps({ message: MessageCode.PURCHASE_LIMIT_ERROR });
        } else {
          this.setProps({ message: MessageCode.UNKNOWN_ERROR });
        }
      }
    }
  }

  async update(
    selectedPlan: PublicPlan,
    integrationData: IntegrationData,
    order?: Order,
    showUpgradeModal = false,
  ): Promise<void> {
    this.flowAPI.fedops.interactionStarted(PackagePickerInteractions.CheckoutPageLoaded);
    this.analytics.viewContent(selectedPlan);
    this.setProps({
      order,
      prices: order?.pricing?.prices ?? (await this.ordersApi.getPricePreview(selectedPlan.id!)),
      selectedPlan,
      logout: this.logout,
      loginOnCheckout: () => this.promptLogin('login', selectedPlan, integrationData),
      signupOnCheckout: () => this.promptLogin('signup', selectedPlan, integrationData),
      navigateToStatus: this.navigateToStatus,
      benefits: this.wixCodeApi.site.getAppToken?.(BOOKINGS_APP_DEF_ID)
        ? await this.benefitsApi.listPlanBookingsBenefits(selectedPlan.id ?? '')
        : [],
      trackInitiateCheckout: () => this.analytics.initiateCheckout(selectedPlan),
      trackSelectPayment: (id: string) => this.analytics.selectPayment(id),
      demoBuyNowClicked: () => this.navigateToDemoStatus(selectedPlan, integrationData),
      biCheckoutStage: (stage) => this.flowAPI.bi?.report(checkoutStage({ stage, planGuid: selectedPlan.id })),
      biPlanPurchased: (result: PaymentCompleteResultPublic, purchacedOrder: Order) =>
        this.flowAPI.bi?.report(
          planPurchased({
            paymentStatus: result.clientStatus,
            paymentMethodType: result.paymentMethod,
            duration:
              (selectedPlan.pricing?.subscription
                ? selectedPlan.pricing.subscription.cycleCount
                : selectedPlan.pricing?.singlePaymentForDuration?.count) ?? undefined,
            paymentType: toBIPaymentType(!!selectedPlan.pricing?.subscription),
            planGuid: selectedPlan.id,
            currency: selectedPlan.pricing?.price?.currency,
            price: Math.round(parseFloat(selectedPlan.pricing?.price?.value ?? '0') * 100),
            membershipId: purchacedOrder.id,
            couponId: getOrderCoupon(purchacedOrder)?.id,
          }),
        ),
      updatePriceDetails: this.updatePriceDetails,
      updatePriceDetailsError: undefined,
      couponInputMode: 'trigger',
      couponCode: undefined,
      removeCoupon: this.removeCoupon,
      onBeforeStartPayment: this.onBeforeStartPayment,
      onBeforeStartPaymentStatus: undefined,
      updateStartDateError: undefined,
      applyCouponError: undefined,
      hasCoupons: await this.ordersApi.hasCoupons(),
      isBassSupported: await this.ordersApi.isBassSupported(),
    });
    const { currentUser } = this.wixCodeApi.user;
    if (currentUser.loggedIn) {
      this.initializeForUser(currentUser, selectedPlan, integrationData, order, showUpgradeModal);
    }
  }

  promptLogin = async (mode: 'signup' | 'login', plan: PublicPlan, integrationData: IntegrationData) => {
    const user = await this.wixCodeApi.user.promptLogin({ mode, modal: true });
    if (user.loggedIn) {
      return this.initializeForUser(user, plan, integrationData, undefined, true);
    }
  };

  initializeForUser = async (
    user: IUser,
    selectedPlan: PublicPlan,
    integrationData: IntegrationData,
    order?: Order,
    showUpgradeModal = false,
  ) => {
    this.updateUserInfo(user);
    if (!isFree(selectedPlan) && (await this.premiumApi.shouldUpgrade())) {
      const isAdmin = user.role === 'Admin';
      return this.setProps({
        message: isAdmin ? MessageCode.CHECKOUT_DEMO : undefined,
        showUpgradeModal: isAdmin && showUpgradeModal,
        shouldUpgrade: true,
      });
    } else if (!order) {
      try {
        order = await this.ordersApi.createOrder(selectedPlan.id!);
        if (order) {
          this.setProps({ order, prices: order?.pricing?.prices });
          if (isFree(selectedPlan)) {
            this.router.gotoStatus(selectedPlan, order, integrationData);
          }
        }
      } catch (e) {
        this.setProps({ message: errorToMessage(toError(e)) });
      }
    }
  };

  updateUserInfo = async (user: IUser) => {
    const userData = getUserData(user);
    try {
      const userEmail = await user.getEmail();
      this.setProps({ user: userData, userEmail });
    } catch (e) {
      this.flowAPI.reportError(new Error('Failed to get user email: ' + e));
      this.setProps({ user: userData });
    }
  };

  navigateToStatus = (
    result: PaymentCompleteResultPublic,
    plan: PublicPlan,
    order: Order,
    integrationData: IntegrationData,
  ) => {
    const resultReader = new PaymentResultReader(result);
    this.router.gotoStatus(plan, order, integrationData, {
      ok: resultReader.isOk(),
      error: resultReader.getTranslatedError(),
    });
  };

  navigateToDemoStatus = (plan: PublicPlan, integrationData: IntegrationData) =>
    this.router.gotoStatus(plan, {}, integrationData, { ownerDemo: true });

  logout = () => {
    this.wixCodeApi.user.logout();
  };

  updateStartDate = async (orderId: string, dateYmd: string) => {
    this.setProps({
      updateStartDateError: undefined,
    });
    const date = ymdToDate(dateYmd);
    // @sarunas: this should be improved to allow reset start date to today
    if (date.getTime() >= Date.now()) {
      try {
        await this.ordersApi.updateOrderValidFrom(orderId, date);
      } catch (e) {
        this.flowAPI.reportError(toError(e));
        this.setProps({
          updateStartDateError: {
            message: toError(e).message, // @todo: map it to actual error message
          },
        });
        throw e;
      }
    }
  };

  updatePriceDetails = async (planId: string, couponCode: string) => {
    try {
      this.setProps({
        couponLoading: true,
        updatePriceDetailsError: undefined,
      });
      const prices = await this.ordersApi.getPricePreview(planId, couponCode);
      this.setProps({
        couponCode,
        couponInputMode: 'coupon',
        couponLoading: false,
        prices,
      });
      this.flowAPI.bi?.report(
        couponActionAtCheckout({
          action: CouponAction.Apply,
          couponCode,
          couponId: getPricesCoupon(prices)?.id,
          planGuid: planId,
        }),
      );
    } catch (e) {
      this.flowAPI.reportError(toError(e));
      const message = HttpClient.isHttpError(e)
        ? this.couponErrorToMessage(e)
        : this.flowAPI.translations.t('payment.coupon-error.unknown');
      this.setProps({ updatePriceDetailsError: { message }, couponLoading: false });
      this.flowAPI.bi?.report(
        couponErrorAtCheckout({
          action: CouponAction.Apply,
          couponCode,
          planGuid: planId,
          errorField: getApplicationErrorCode(e) ?? 'Unknown Error',
        }),
      );
    }
  };

  removeCoupon = async (planId: string, couponId?: string) => {
    this.setProps({
      couponCode: '',
      couponInputMode: 'input',
      updatePriceDetailsError: undefined,
    });
    this.flowAPI.bi?.report(
      couponActionAtCheckout({
        action: CouponAction.Remove,
        couponId,
        planGuid: planId,
      }),
    );
    try {
      this.setProps({
        prices: await this.ordersApi.getPricePreview(planId),
      });
    } catch (e) {
      this.flowAPI.reportError(toError(e));
    }
  };

  couponErrorToMessage = (e: HttpError) => {
    const errorCodeTranslationMap = {
      ERROR_COUPON_IS_DISABLED: 'payment.coupon-error.coupon-is-disabled',
      ERROR_COUPON_USAGE_EXCEEDED: 'payment.coupon-error.coupon-usage-exceeded',
      ERROR_COUPON_LIMIT_PER_CUSTOMER_EXCEEDED: 'payment.coupon-error.coupon-limit-per-customer-exceeded',
      ERROR_COUPON_IS_NOT_ACTIVE_YET: 'payment.coupon-error.coupon-is-not-active-yet',
      ERROR_COUPON_HAS_EXPIRED: 'payment.coupon-error.coupon-has-expired',
      ERROR_COUPON_DOES_NOT_EXIST: 'payment.coupon-error.coupon-does-not-exists',
      ERROR_COUPON_NOT_APPLICABLE_FOR_PLAN: 'payment.coupon-error.coupon-not-applicable-for-plan',
      ERROR_COUPON_ALREADY_APPLIED: 'payment.coupon-error.coupon-already-applied',
      COUPON_ALREADY_APPLIED: 'payment.coupon-error.coupon-already-applied',
      ERROR_INVALID_SUBTOTAL: 'payment.coupon-error.invalid-subtotal',
    };
    const errorCode: keyof typeof errorCodeTranslationMap = getApplicationErrorCode(e);
    return this.flowAPI.translations.t(errorCodeTranslationMap[errorCode] ?? 'payment.coupon-error.unknown');
  };

  applyCoupon = async (orderId: string, planId: string, couponCode: string) => {
    this.setProps({
      applyCouponError: undefined,
    });
    try {
      const order = await this.ordersApi.applyCoupon(orderId, couponCode);
      this.setProps({
        order,
        prices: order?.pricing?.prices,
      });
    } catch (e) {
      let message;
      let code;
      if (HttpClient.isHttpError(e)) {
        code = getApplicationErrorCode(e);
        if (code === 'COUPON_ALREADY_APPLIED') {
          // @todo handle how to remove coupon for real
          return;
        } else {
          message = this.couponErrorToMessage(e);
        }
      } else {
        message = this.flowAPI.translations.t('payment.coupon-error.unknown');
      }

      this.flowAPI.reportError(toError(e));
      this.setProps({
        couponInputMode: 'input',
        applyCouponError: {
          message,
        },
      });
      this.flowAPI.bi?.report(
        couponErrorAtCheckout({
          action: CouponAction.Apply,
          couponCode,
          planGuid: planId,
          errorField: code ?? 'Unknown Error',
        }),
      );
      throw e;
    }
  };

  onBeforeStartPayment: PromisifyFn<OnBeforeStartPaymentFn> = async (orderId, planId, { startDate, couponCode }) => {
    this.setProps({
      onBeforeStartPaymentStatus: undefined,
    });
    let success = true;
    try {
      if (startDate) {
        await this.updateStartDate(orderId, startDate);
      }
      if (couponCode) {
        await this.applyCoupon(orderId, planId, couponCode);
      }
    } catch (e) {
      success = false;
    }
    this.setProps({
      onBeforeStartPaymentStatus: { success },
    });
  };
}
